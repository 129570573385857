<template>
  <vxe-column
    :field="dynamicField.field_name"
    :title="dynamicField.title"
    :edit-render="editRender"
    show-overflow="ellipsis"
    width="300"
  >
    <template #default="{ row }">
      <span>{{ formatValue(row[dynamicField.field_name]) }}</span>
    </template>
    <template #edit="scope">
      <vxe-select v-model="scope.row[dynamicField.field_name]" transfer>
        <vxe-option v-for="item in items" :key="item.id" :value="item.id"
                    :label="item.title"/>
      </vxe-select>
    </template>
  </vxe-column>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Field",
  async created() {
    this.items = (await this.fetchCollectionItems(this.dynamicField.extra_options.taxonomy_id)).data.data;
  },
  props: {
    dynamicField: {
      type: Object,
      require: true,
    },
    tableRef: {
      require: true,
    }
  },
  data: () => ({
    editRender: {
      autofocus: '.myinput'
    },
    items: [],
  }),
  computed: {
    isMultiple() {
      return this.dynamicField.extra_options.multiple
    }
  },
  methods: {
    ...mapActions({
      fetchCollectionItems: "Fields/fetchFieldTaxonomy",
    }),
    formatValue(value) {
      if (value) {
        if (!this.isMultiple) {
          const label = this.items.find((item) => {
            return item.id === value;
          })

          if (label !== undefined) {
            return label.title;
          }
        }
      }

      return "-";
    }
  }
}
</script>

<style scoped>

</style>
